import React from "react"
import styled from "styled-components"
import { blue, desktop } from "../constants"

const FooterWrapper = styled.footer`
  background-color: ${blue};
  padding: 15px 30px;
  margin-top: 30px;

  p {
    color: white;
    text-align: center;
  }

  @media (min-width: ${desktop}) {
    display: flex;
    justify-content: space-between;
  }
`

const Copyright = styled.p`
  text-transform: uppercase;
  margin-bottom: 15px;
  @media (min-width: ${desktop}) {
    margin-bottom: 0px;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <Copyright>
        &copy;{new Date().getFullYear()} H&amp;A couriers. All rights reserved.
      </Copyright>
      <p>
        Armando{" "}
        <a href="tel:083-469-5935">
          <strong>083 469 5935</strong>
        </a>{" "}
        or Hennie{" "}
        <a href="tel:083-701-3163">
          <strong>083 701 3163</strong>
        </a>
      </p>
    </FooterWrapper>
  )
}

export default Footer
