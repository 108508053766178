import React from "react"
import styled from "styled-components"
import { orange, desktop } from "../constants"
import LogoImage from '../images/ha-logo.png'
import PhoneIcon from '../images/phone-icon.png'
import EmailIcon from '../images/email-icon.png'

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
`

const ContactButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${orange};
  color: white;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  transition: opacity ease 300ms;

  span {
    font-weight: bold;
    display: none;
    font-family: "Gilroy";
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  @media (min-width: ${desktop}) {
    padding: 10px 30px;
    border-radius: 30px;
    width: auto;
    height: auto;
    span {
      display: block;
    }
  }
`

const Logo = styled.img`
  width: 200px;
  max-width: 100%;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PhoneImg = styled.img`
  height: 18px;
  margin-right: 20px;
`

const EmailImg = styled.img`
  height: 15px;

  @media (min-width: ${desktop}) {
    margin-right: 10px;
  }
`

const Header = () => (
  <HeaderWrapper>
    <Logo src={LogoImage} alt="H&A Couriers Logo" />
    <ActionsWrapper>
      <a href="tel:083-469-5935">
        <PhoneImg src={PhoneIcon} alt="Phone H&A Couriers" />
      </a>
      <ContactButton href="mailto:info@hacouriers.co.za">
        <EmailImg src={EmailIcon} alt="Email H&A Couriers" />
        <span>Contact us</span>
      </ContactButton>
    </ActionsWrapper>
  </HeaderWrapper>
)

export default Header
