import React from "react"
import styled from "styled-components"
import { blue, orange, desktop } from "../../constants"
import TruckImage from '../../images/truck.png'
import SameDayIcon from '../../images/icon-1.png'
import OvernightIcon from '../../images/icon-2.png'
import AirportIcon from "../../images/icon-3.png"

const Banner = styled.div`
  margin-top: 20px;
  padding: 20px;

  @media (min-width: ${desktop}) {
    display: flex;
    align-items: center;
  }
`

const BannerImage = styled.img`
  margin-top: 30px;
  margin-bottom: 50px;
  @media (min-width: ${desktop}) {
    width: 30vw;
    margin-bottom: 0px;
    margin-left: 80px;
    margin-top: 0px;
  }
`

const Title = styled.h1`
  color: ${orange};
  font-size: 20vw;
  @media (min-width: ${desktop}) {
    font-size: 8vw;
    line-height: 8.5vw;
  }
`

const InfoList = styled.div`
  @media (min-width: ${desktop}) {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: ${desktop}) {
    margin-bottom: 0px;
  }
`

const InfoTitle = styled.h2`
  color: ${blue};
  font-size: 30px;
  margin-bottom: 10px;
`

const InfoDescription = styled.p`
  color: ${blue};
  font-size: 20px;
  font-weight: 300;
`

const InfoIcon = styled.img`
  width: 80px;
  margin-bottom: 30px;
`

const DeliverText = styled.h2`
  color: ${blue};
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  padding: 0px 15px;
`

const HomePage = () => {
  return (
    <>
      <Banner>
        <Title>
          Trusted,
          <br />
          Delivery.
        </Title>

        <BannerImage src={TruckImage} alt="H&A Couriers" />
      </Banner>

      <InfoList>
        <InfoBlock>
          <InfoIcon src={SameDayIcon} alt="H&A Couriers" />
          <InfoTitle>Same day</InfoTitle>
          <InfoDescription>Pretoria and Johannesburg</InfoDescription>
        </InfoBlock>

        <InfoBlock>
          <InfoIcon src={OvernightIcon} alt="H&A Couriers" />
          <InfoTitle>Overnight</InfoTitle>
          <InfoDescription>Rest of South Africa</InfoDescription>
        </InfoBlock>

        <InfoBlock>
          <InfoIcon src={AirportIcon} alt="H&A Couriers" />
          <InfoTitle>Airport to Airport</InfoTitle>
          <InfoDescription>All major airports</InfoDescription>
        </InfoBlock>
      </InfoList>

      <DeliverText>Trust us to deliver your parcels and documents.</DeliverText>
    </>
  )
}

export default HomePage
